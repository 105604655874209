class Elementor_Portfolio {
    static instance;

    static getInstance() {
        if (!Elementor_Portfolio.instance) {
            Elementor_Portfolio.instance = new Elementor_Portfolio();
        }
        return Elementor_Portfolio.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-portfolio.default', ($scope) => {
            let $container = $scope.find('.isotope-grid');
            let currentIsotope = $scope.find('.isotope-grid').isotope({filter: '*'});
            $scope.find('.elementor-portfolio__filters li').on('click', function () {
                $(this).parents('ul.elementor-portfolio__filters').find('li.elementor-portfolio__filter').removeClass('elementor-active');
                $(this).addClass('elementor-active');
                let selector = $(this).attr('data-filter');
                currentIsotope.isotope({filter: selector});
            });

            currentIsotope.imagesLoaded(function () {
                currentIsotope.isotope('layout');
            });

            let $button = $scope.find('a.elementor-button-load-more');

            $button.on('click', function (e) {
                e.preventDefault();
                let data = $(this).data('settings');
                let paged = $(this).data('paged');
                $.ajax({
                    url: osfAjax.ajaxurl,
                    data: {
                        action: 'osf_ajax_loadmore_post',
                        data: data,
                        paged: paged
                    },
                    type: 'POST',
                    dataType: 'JSON',
                    beforeSend: function () {
                        $('body').addClass('loading');
                    },
                    success: function (response) {
                        for (let item of response.posts) {
                            let $newItems = $(item);
                            $container.append($newItems)
                                .isotope('appended', $newItems)

                            $newItems.imagesLoaded(function(){
                                $container.isotope('layout');
                            })
                        }
                        $button.data('paged', response.paged);
                        if (response.disable) {
                            $button.remove();

                        }
                        $('body').removeClass('loading');
                    }
                });
            });

            let $carousel = $scope.find('.owl-carousel');
            if($carousel.length > 0){
                let data = $carousel.data('settings');
                $carousel.owlCarousel(Elementor_Carousel.setupData(data));
            }

        });
    }
}

Elementor_Portfolio.getInstance();
class Elementor_Product_Deals{
    static instance;

    static getInstance() {
        if (!Elementor_Product_Deals.instance) {
            Elementor_Product_Deals.instance = new Elementor_Product_Deals();
        }
        return Elementor_Product_Deals.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-product-deals.default', ($scope) => {
            let $carousel = $('.woocommerce-carousel', $scope);
            if ($carousel.length > 0) {
                let data = $carousel.data('settings');
                $('ul.products', $carousel).owlCarousel(Elementor_Carousel.setupData(data));
            }
        });
    }
}

Elementor_Product_Deals.getInstance();


class Elementor_Products_Tabs {
    static instance;

    static getInstance() {
        if (!Elementor_Products_Tabs.instance) {
            Elementor_Products_Tabs.instance = new Elementor_Products_Tabs();
        }
        return Elementor_Products_Tabs.instance;
    }

    constructor() {
        $(window).on('elementor/frontend/init', () => {
            this.init();
        });
    }

    init() {
        elementorFrontend.hooks.addAction('frontend/element_ready/opal-products-tabs.default', ($scope) => {
            // $scope.addClass('elementor-widget-tabs');
            let $tabs = $scope.find('.elementor-tabs-wrapper');
            let $contents = $scope.find('.elementor-tabs-content-wrapper');
            $contents.find('.elementor-tab-content').hide();
            // Active tab
            $contents.find('.elementor-active').show();
            $tabs.find('.elementor-tab-title').on('click', function () {
                $tabs.find('.elementor-tab-title').removeClass('elementor-active');
                $contents.find('.elementor-tab-content').removeClass('elementor-active').hide();
                $(this).addClass('elementor-active');
                let id = $(this).attr('aria-controls');
                $contents.find('#' + id).addClass('elementor-active').show();
            })
            let $carousel        = $('.woocommerce-carousel', $scope),
                $product_two_row = $scope.find('.product-two-row');
            if ($product_two_row.length > 0) {
                this.AddWrap($scope);
            }
            if ($carousel.length > 0) {
                let data = $carousel.data('settings');
                $('ul.products', $carousel).owlCarousel(Elementor_Carousel.setupData(data));
            }
        });
    }

    AddWrap($scope) {
        let list = $scope.find('.product-two-row ul.products > li');
        for (var i = 0; i < list.length; i += 2) {
            list.slice(i, i + 2).wrapAll('<li><ul class="product-two-row"></ul></li>');
        }
    }
}

Elementor_Products_Tabs.getInstance();
class Elementor_Carousel {
    static setupData(data) {
        switch (data.navigation) {
            case 'both':
                data.dots = true;
                data.nav = true;
                break;
            case 'arrows':
                data.dots = false;
                data.nav = true;
                break;
            case 'dots':
                data.dots = true;
                data.nav = false;
                break;
            case 'none':
                data.dots = false;
                data.nav = false;
                break;
        }
        switch (data.loop) {
            case 'false':
                data.loop = false;
                break;
            case 'true':

                data.loop = true;
                break;
        }
        data.responsive = {
            0: {
                items: data.items_mobile,
                 margin: parseInt(data.margin_mobile)
            },
            768: {
                items: data.items_tablet,
                 margin: parseInt(data.margin_tablet)
            },
            1024: {
                items: data.items,
                margin: parseInt(data.margin)
            }
        }

        // data.margin = data.margin ? parseInt(data.margin) : 0;


        return data;
    }
}